<template>
  <v-row justify="center">
    <v-form
        v-model="valid" ref="formData"
        lazy-validation
    >
      <v-card style="margin: 10px">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon v-if="titleClick==='addProperty'" large color="green darken-2"
                  style="font-size: 50px !important;">mdi-plus-thick
          </v-icon>
          <v-icon v-if="titleClick==='updateProperty'" large color="green darken-2"
                  style="font-size: 50px !important;">mdi-pencil-box-multiple
          </v-icon>
          <h1 class="headline">{{ $t(titleClick) }}</h1>
          <v-spacer></v-spacer>

        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" v-if="!$vuetify.breakpoint.mobile">
              <div id="style-selector-control" class="map-control">
                  <input
                    type="radio"
                    name="isMarker"
                    id="marker"
                    class="selector-control"
                    checked="checked"
                    @click="() => { dataObj.isMarker = true}"
                  />
                  <label for="marker">Marker</label> &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="isMarker"
                    id="circle"
                    class="selector-control"
                    @click="() => { dataObj.isMarker= false}"
                  />
                  <label for="circle">Circle</label>
                </div>
              <gmaps-map :options="mapOptions" id="map" @mounted="ready" @click="(mapsMouseEvent)=>{ updatePosition(JSON.parse(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)))}">
                <gmaps-marker
                  v-if="dataObj.isMarker"
                  :options="optionsA"
                  color="purple"
                  @move="updatePosition"
                />
                <gmaps-circle
                  v-else
                  :center="circle.center"
                  :radius="circle.radius"
                  fillColor="purple"
                  :strokeWeight="0"
                  :draggable="true"
                  @center-changed="updatePosition"
                />
              </gmaps-map>
              
            </v-col>
            <v-col cols="12" md="6">
              <v-row>

                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                      v-model="newUrlList"
                      @click="$refs.fileInput.click()"
                      :label="$t('uploadPhoto')"
                      outlined
                      rounded
                      :suffix="newUrlList.length+' ' + $t('photo')"
                  >

                  </v-text-field>

                  <input style="display: none !important;" type="file"
                         @change="onFileSelectedList($event)"
                         multiple
                         accept="image/*"
                         ref="fileInput"/>

                </v-col>
                <v-col cols="12" sm="12" md="12" style="padding-top: 0px !important;padding-bottom: 0px !important;">
                    <v-row class="flex">
                      <CoolLightBox
                          :items="newUrlList || []"
                          :index="index"
                          @close="index = null">
                      </CoolLightBox>
                      <v-col
                          v-for="(imgUrl,i) in newUrlList"
                          :key="imgUrl"
                          class="d-flex"
                          cols="2"
                      >
                        <v-img
                            :src="imgUrl"
                            lazy-src="/images/no-image-icon.png"
                            aspect-ratio="1"
                            class="grey lighten-2"
                            @click="index = i"
                            style="cursor: pointer"
                        >
                          <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                              <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                        <v-btn
                          class="ml-n5 mt-n3"
                          elevation="2"
                          fab
                          dark
                          x-small
                          color="error"
                          @click="removeImage(i)"
                        >
                          <v-icon dark>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-btn-toggle
                      class="animate__animated animate__flipInX animate__duration-5s"
                      color="deep-purple accent-3"
                      rounded
                      required
                      :dense="dense"
                      v-model="dataObj.groupType"
                      style="font-size: 11px"
                  >
                    <v-btn value="sale" style="min-width: 60px !important;">
                      {{ $t('forSale') }}
                    </v-btn>
                    <v-btn value="rent" style="min-width: 60px !important;">
                      {{ $t('forRent') }}

                    </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="d-flex">
                  <v-autocomplete
                      v-model="dataObj.phoneNumberPrefix"
                      :items="phoneNumberPrefixList"
                      outlined
                      required
                      :dense="dense"
                      color="blue-grey lighten-2"
                      :label="$t('country')"
                      item-text="name"
                      item-value="number"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                      >
                        <v-avatar left>
                          <v-img :src="data.item.flag" :alt="data.item.name"></v-img>
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar>
                        <img :src="data.item.flag" :alt="data.item.name">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--                <v-col cols="12" sm="6" md="6" class="d-flex" v-if="stateList.length>0">
                                  <v-autocomplete
                                      v-model="dataObj.stateId"
                                      :items="stateList"
                                      outlined
                                      :dense="dense"
                                      color="blue-grey lighten-2"
                                      :label="$t('state')"
                                      item-text="label"
                                      item-value="value"
                                  >
                                    <template v-slot:selection="data">
                                      <v-chip
                                          v-bind="data.attrs"
                                          :input-value="data.selected"
                                          close
                                      >

                                        {{ data.item.label }}
                                      </v-chip>
                                    </template>
                                    <template v-slot:item="data">
                                      <v-list-item-content>
                                        <v-list-item-title v-html="data.item.label"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </v-autocomplete>
                                </v-col>-->
                <v-col cols="12" sm="6" md="6" class="d-flex">
                  <v-autocomplete v-model="dataObj.locationId"
                                  :items="locationOptionList"
                                  outlined
                                  :dense="dense"
                                  clearable
                                  item-text="label"
                                  item-value="value"
                                  :label="$t('city')"
                                  required
                                  :rules="requireInput"
                                  search-input
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.label }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="d-flex">
                  <v-autocomplete v-model="dataObj.districtId"
                                  :items="districtOptionList"
                                  outlined
                                  :dense="dense"
                                  clearable
                                  item-text="label"
                                  item-value="value"
                                  :label="$t('district')"
                                  required
                                  :rules="requireInput"
                                  search-input
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.label }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete v-model="dataObj.type"
                                  :items="typeOption"
                                  rounded
                                  outlined
                                  clearable
                                  item-text="label"
                                  item-value="value"
                                  search-input
                                  :rules="requireInput"
                                  dense
                                  prepend-inner-icon="mdi-shape-outline"
                                  required
                                  :placeholder="$t('propertyType')"
                  >
                    <template v-slot:selection="{ item }">
                      {{ $t(item.label) }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ $t(item.label) }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="d-flex" v-if="dataObj.type==='condo'">
                  <v-autocomplete v-model="dataObj.projectId"
                                  :items="projectOption"
                                  outlined
                                  :dense="dense"
                                  clearable
                                  item-text="label"
                                  item-value="value"
                                  :label="$t('project')"
                                  required
                                  :search-input.sync="searchProject"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.label }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="d-flex" v-if="(['land','farmLand'].indexOf(dataObj.type)>-1)">
                  <v-select
                      v-model="dataObj.titleType"
                      :items="titleTypeOpt"
                      :dense="dense"
                      chips
                      item-text="label"
                      item-value="value"
                      :label="$t('titleType')"
                      small-chips
                      outlined
                  >
                    <template v-slot:item="{ item }">
                      {{ $t(item.label) }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ $t(item.label) }}
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="6" sm="3" md="3" class="d-flex">
                  <v-autocomplete
                      v-model="dataObj.currencyCode"
                      :items="currencyList"
                      outlined
                      required
                      :dense="dense"
                      color="blue-grey lighten-2"
                      :label="$t('currency')"
                      item-text="code"
                      item-value="code"
                      :rules="requireInput"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" sm="3" md="3" class="d-flex">
                  <v-autocomplete
                      v-model="dataObj.dimension"
                      :items="dimensionList"
                      outlined
                      required
                      :dense="dense"
                      color="blue-grey lighten-2"
                      :label="$t('dimension')"
                      item-text="label"
                      item-value="value"
                      :rules="requireInput"
                  >
                    <template v-slot:selection="{ item }">
                      {{ item.value }}
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6" v-if="!(['condo','apartment','office'].indexOf(dataObj.type)>-1)">
                  <v-text-field
                      v-model="dataObj.width"
                      :label="$t('width')"
                      persistent-hint
                      type="number"
                      required
                      :dense="dense"
                      :rules="requireInput"
                  >
                    <template v-slot:append>
                      <span style="padding-top: 10px">{{ dataObj.dimension }}</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="!(['condo','apartment','office'].indexOf(dataObj.type)>-1)">
                  <v-text-field
                      v-model="dataObj.depth"
                      :label="$t('depth')"
                      persistent-hint
                      type="number"
                      required
                      :dense="dense"
                      :rules="requireInput"
                  >
                    <template v-slot:append>
                      <span style="padding-top: 10px">{{ dataObj.dimension }}</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                      v-model="dataObj.size"
                      :label="$t('size')"
                      type="number"
                      persistent-hint
                      required
                      :dense="dense"
                  >
                    <template v-slot:append>
                      <span style="padding-top: 10px">{{ dataObj.dimension }}<sup>2</sup> </span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                      v-model="dataObj.lastPrice"
                      type="number"
                      :label="$t('lastPrice')"
                      persistent-hint
                      :suffix="dataObj.currencySymbol"
                      required
                      :dense="dense"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                      v-model="dataObj.price"
                      :rules="requireInput"
                      type="number"
                      :label="$t('price')"
                      persistent-hint
                      :suffix="dataObj.currencySymbol"
                      required
                      :dense="dense"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                      v-model="dataObj.pricePerSquare"
                      :label="$t('pricePerSquare')"
                      type="number"
                      persistent-hint
                      :suffix="dataObj.currencySymbol"
                      required
                      :dense="dense"
                      disabled
                      filled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="!isFreeMember">
                  <v-text-field
                      v-model="dataObj.commissionFee"
                      :label="$t('commissionFee')"
                      type="number"
                      persistent-hint
                      suffix="%"
                      required
                      :dense="dense"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" v-if="titleClick==='updateProperty'">
                  <v-text-field
                      v-model="dataObj.title"
                      :rules="requireInput"
                      :label="$t('title')"
                      persistent-hint
                      required
                      :dense="dense"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" v-if="titleClick==='addProperty'">

                  <v-text-field v-if="!dataObj.insertRank"
                                v-model="dataObj.title"
                                :rules="requireInput"
                                :label="$t('title')"
                                persistent-hint
                                required
                                :dense="dense"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                      v-model="dataObj.desc"
                      :label="$t('description')"
                      persistent-hint
                      required
                      row="2"
                      row-height="12"
                      auto-grow
                      :dense="dense"
                      :rules="requireInput"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                      v-model="dataObj.address"
                      :label="$t('address')"
                      persistent-hint
                      required
                      :dense="dense"
                      :rules="requireInput"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="!(['land','farmLand'].indexOf(dataObj.type)>-1)">
                  <v-text-field
                      v-model="dataObj.numBed"
                      :label="$t('numBed')"
                      persistent-hint
                      required
                      :dense="dense"
                      :rules="requireInput"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" v-if="!(['land','farmLand'].indexOf(dataObj.type)>-1)">
                  <v-text-field
                      v-model="dataObj.numBathroom"
                      :label="$t('numBathroom')"
                      persistent-hint
                      required
                      :dense="dense"
                      :rules="requireInput"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" class="d-flex" v-if="!(['land','farmLand'].indexOf(dataObj.type)>-1)">
                  <v-autocomplete v-model="dataObj.propertyStatus"
                                  :items="propertyStatusOpt"
                                  outlined
                                  :dense="dense"
                                  clearable
                                  item-text="label"
                                  item-value="value"
                                  :label="$t('propertyStatus')"
                                  search-input
                                  required
                                  :rules="requireInput"
                  >
                    <template v-slot:selection="{ item }">
                      {{$t(item.label) }}
                    </template>
                    <template v-slot:item="{ item }">
                      {{ $t(item.label) }}
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                      v-model="dataObj.linkYoutube"
                      :label="$t('linkYoutube')"
                      persistent-hint
                      required
                      :dense="dense"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="12" v-if="dataObj.type==='condo'">
                  {{ $t("amenity") }}
                  <v-row>
                    <v-col
                        v-for="(d,i) in amenityListOpt"
                        :key="i"
                        cols="6"
                        sm="3"
                        md="3"
                    >
                      <v-checkbox
                          style="margin-top: 0px !important;padding-top: 0px !important;"
                          v-model="dataObj.additional"
                          :label="d.name"
                          color="green"
                          :value="d.value"
                          hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>



                <!--<v-col cols="12" md="6" sm="6" xs="6">
                  <v-select
                      v-model="dataObj.status"
                      :items="statusOption"
                      :dense="dense"

                      chips
                      :label="$t('status')"
                      small-chips
                      outlined
                  ></v-select>
                </v-col>-->


              </v-row>
            </v-col>
            <v-col cols="12" md="6" v-if="$vuetify.breakpoint.mobile"
                   style="height: 450px !important;">
                  <div id="style-selector-control" class="map-control">
                  <input
                    type="radio"
                    name="isMarker"
                    id="marker"
                    class="selector-control"
                    checked="checked"
                    @click="() => { dataObj.isMarker = true}"
                  />
                  <label for="marker">Marker</label> &nbsp;&nbsp;
                  <input
                    type="radio"
                    name="isMarker"
                    id="circle"
                    class="selector-control"
                    @click="() => { dataObj.isMarker= false}"
                  />
                  <label for="circle">Circle</label>
                </div>
              <gmaps-map :options="mapOptions" id="map" @mounted="ready" @click="(mapsMouseEvent)=>{ updatePosition(JSON.parse(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2)))}">
                <gmaps-marker
                  v-if="dataObj.isMarker"
                  :options="optionsA"
                  color="purple"
                  @move="updatePosition"
                />
                <gmaps-circle
                  v-else
                  :center="circle.center"
                  :radius="circle.radius"
                  fillColor="purple"
                  :strokeWeight="0"
                  :draggable="true"
                  @center-changed="updatePosition"
                />
              </gmaps-map>
            </v-col>


          </v-row>


        </v-card-text>

        <v-card-actions>

          <v-spacer></v-spacer>
          <reset-button @resetForm="resetForm" valid="false"></reset-button>
          <save-button @saveForm="handleSubmit"
                       :valid="valid"></save-button>

        </v-card-actions>
      </v-card>
    <loading-circle v-if="isLoading"></loading-circle>
    </v-form>
  </v-row>
</template>

<script>
import SaveButton from "../components/saveButton"
import ResetButton from "../components/resetButton";
import {Constants} from "../libs/constant";

import MainMixin from "../mixins/mainMixin";
import "../firebase/config";
//import _ from 'lodash'
import numeral from "numeral";
import {countyMobileList} from "../libs/countryMobileWithFlag";
import {currencyList} from "../libs/currencyList";
import {gmapsMap, gmapsMarker, gmapsCircle} from 'x5-gmaps'
import {provider} from "@/service/provider";
import axios from "axios";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import loadingCircle from "../components/loadingCircle.vue"
import moment from "moment";

const Compress = require('compress.js')
export default {
  mounted() {
    this.$jQuery('body').off();
    this.$GMaps()
        .then((GMaps) => {
          this.optionsA.animation = GMaps.Animation.BOUNCE;
          this.optionsA.draggable = true;
        })
  },
  mixins: [MainMixin],
  name: "Property",
  components: {SaveButton, ResetButton, gmapsMap, gmapsMarker, gmapsCircle, CoolLightBox, loadingCircle},
  data() {
    return {
      bottomValue: 1,
      active: true,
      isDraggable: true,
      dense: this.$store.state.isDense,
      isLoading: false,
      fullPage: false,
      dialog: false,
      search: '',
      currentPage: 1,
      pageCount: 0,
      totalPage: 0,
      itemsPerPage: 10,

      amenityListOpt: Constants.amenityList,

      sortBy: "",
      sortDesc: "",
      options: {},
      skip: 0,
      limit: 10,
      filter: "",
      loading: true,
      valid: true,
      phoneNumberPrefixList: countyMobileList,
      currencyList: currencyList,

      titleClick: "",
      pageList: [10, 20, 50, 100, 200],
      dataObj: {
        _id: "",
        title: "",
        unitId: "",
        stateId: "",
        locationId: "",
        districtId: "",
        price: "",
        lastPrice: "",
        insertRank: false,
        titleType: "",
        prefix: "",
        linkYoutube: "",
        phoneNumberPrefix: "",
        compensationId: "",
        from: "",
        to: "",
        pricePerSquare: "",
        status: "Free",
        groupType: "sale",
        address: "",
        projectId: "",
        mapEmbedUrl: "",
        currencyCode: "USD",
        currencySymbol: "$",
        desc: "",
        urlList: [],
        url1: "",
        url2: "",
        url3: "",
        url4: "",
        url5: "",
        url6: "",
        url7: "",
        url8: "",

        propertyStatus: "ready",
        numBed: "",
        numBathroom: "",
        length: "",
        size: "",
        depth: "",
        width: "",
        code: "",
        position: {
          lat: 12.5225312, lng: 104.920603
        },
        dimension: "m",
        additional: [],
        isMarker: true,
        commissionFee: ""
      },
      propertyStatusOpt: [
        {label: "ready", value: "ready"},
        {label: "inProgress", value: "inProgress"},
      ],
      nameRules: [
        v => !!v || 'Property Name is required',
      ],
      codeRules: [
        v => !!v || 'Property Code is required',
      ],
      requireInput: [
        v => !!v || 'Please Input Data',
      ], selectRules: [
        v => !!v || 'Please Choose one',
      ],
      countryWithStateList: ["+1"],

      headers: [
        {
          text: 'title',
          align: 'left',
          sortable: true,
          value: 'title',
        }, {
          text: 'code',
          align: 'left',
          sortable: true,
          value: 'code',
        },
        /*{
          text: 'uploadVideo',
          align: 'left',
          sortable: true,
          value: 'linkYoutube',
        },
        {
          text: 'unit',
          align: 'left',
          sortable: true,
          value: 'unitDoc.name',
        },*/
        {
          text: 'description',
          align: 'left',
          sortable: true,
          value: 'desc',
        }, /*{
          text: 'project',
          align: 'left',
          sortable: true,
          value: 'projectDoc.name',
        },*/

        /*{
          text: 'compensation',
          align: 'left',
          sortable: true,
          value: 'compensationDoc.name',
        },*/
        {
          text: 'price',
          align: 'left',
          sortable: true,
          value: 'price',
        }, {
          text: 'status',
          align: 'left',
          sortable: true,
          value: 'status',
        },/* {
          text: 'seller',
          align: 'left',
          sortable: true,
          value: 'staffDoc.name',
        },*/
        {text: 'actions', value: 'action', sortable: false, width: "120px"},
      ],
      dataLists: [],
      unitOption: [],
      searchUnit: "",
      searchProject: "",
      statusList: Constants.productStatusListFilter,
      status: "All",
      compensationOption: [],
      searchCompensation: "",
      statusOption: Constants.productStatusList,
      titleTypeOpt: Constants.titleType,
      newUrlList: [],
      newUrl1: "",
      newUrl2: "",
      newUrl3: "",
      newUrl4: "",
      newUrl5: "",
      newUrl6: "",
      newUrl7: "",
      newUrl8: "",
      typeOption: Constants.propertyType,

      optionsA: {
        position: {lat: 12.5225312, lng: 104.920603},
        icon: {
          url: "https://maps.google.com/mapfiles/ms/micons/purple-dot.png"
        },
        title: "Select Your Location"
      },
      circle: {
        center: { lat: 12.5225312, lng: 104.920603 },
        radius: 500
      },

      optionsB: {
        position: {lat: 12.5225312, lng: 104.920603},
        // icon:"../assets/marker2.png",
        title: "Marker B",
        opacity: 0.7
      },
      mapOptions: {
        center: {lat: 12.5225312, lng: 104.920603},
        // zoom: 7.92,
        zoom: 14,
        // maxZoom: 16
        /*fullscreenControl: false,
        mapTypeControl: false,
        rotateControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false*/
      },
      selectedFile: {},
      fileName: "",
      dimensionList: Constants.dimensionList,
      rawFileImageList: [],
      isFreeMember: true,
      index: null,
      selectedImages: [],
      imgToDelete: []

    }
  },
  methods: {
    ready(map) {
      this.$GMaps().then((maps)=>{
        const styleControl = document.getElementById("style-selector-control");
        map.controls[maps.ControlPosition.TOP_CENTER].push(styleControl);
      })
    },

    increaseOpacity() {
      const op = this.optionsB.opacity * 10
      this.optionsB.opacity = Math.round(op > 9 ? 5 : op + 1) / 10
    },
    getLocation() {
      if (navigator.geolocation) navigator.geolocation.getCurrentPosition(this.setLocation, this.locationError)
      else alert('Geolocation is not supported by this browser.')
    },
    setLocation(pos) {
      this.mapOptions = {...this.mapOptions, center: {lat: pos.coords.latitude, lng: pos.coords.longitude}}
      this.center = {lat: pos.coords.latitude, lng: pos.coords.longitude}
    },
    locationError(error) {
      if (error.PERMISSION_DENIED) alert('User denied the request for Geolocation.')
      else if (error.POSITION_UNAVAILABLE) alert('Location information is unavailable.')
      else if (error.TIMEOUT) alert('The request to get user location timed out.')
      else alert('An unknown error occurred.')
    },
    updatePosition(pos) {
      let vm = this;
      vm.optionsA.position = pos; 
      vm.circle.center = pos;
      vm.dataObj.position = pos;
      // console.log(pos.lat +"Param lat" + pos.lng);
      // console.log(vm.dataObj.position.lat +" pos lat " + vm.dataObj.position.lng)
    },
    resetForm() {
      this.$refs.formData.reset();
    },
    checkRole(roles){
      return localStorage.userType===roles;
    },
    onFileSelectedList(e) {
      let vm = this;
      const files = [...e.target.files];
      if(vm.newUrlList.length < 10){

        // vm.rawFileImageList = files;
        const compress = new Compress();
        compress.compress(files, {
          size: 4, // the max size in MB, defaults to 2MB
          quality: 0.90, // the quality of the image, max is 1,
          maxWidth: 1920, // the max width of the output image, defaults to 1920px
          maxHeight: 1920, // the max height of the output image, defaults to 1920px
          resize: true // defaults to true, set false if you do not want to resize the image width and height
        }).then((data) => {
          // vm.newUrlList = [];
          data.forEach((obj) => {
            let img1 = obj;
            let base64str = img1.data;
            let imgExt = img1.ext;
            if(vm.newUrlList.length < 10){
              vm.newUrlList.push(URL.createObjectURL(Compress.convertBase64ToFile(base64str, imgExt)));
              vm.selectedImages.push(Compress.convertBase64ToFile(base64str, imgExt));
            }
            // if(vm.newUrlList.length + vm.selectedImages.length < 10){
              // vm.selectedImages.push(Compress.convertBase64ToFile(base64str, imgExt));
            // }
            // console.log(vm.selectedImages.length)
            // console.log(vm.newUrlList.length)
            // console.log(URL.createObjectURL(Compress.convertBase64ToFile(base64str, imgExt)))
            // vm.onUploadList(Compress.convertBase64ToFile(base64str, imgExt), obj.alt.split(".")[0]);
          })
        })
      }else {
        vm.$message({
          message: this.$t('uploadImageLimit'),
          type: 'error'
        });
      }
      // console.log(vm.selectedImages.length)
    },
    getUploadUrl() {
      let vm = this;
      if(vm.selectedImages.length > 0){
        let url = provider.baseURL + `/property/uploadUrl?count=${vm.selectedImages.length}`;
        return new Promise((resolve, reject) => {
            axios.get(url, {headers: {token: provider.token}}).then(
                res => {
                  if (res.data.code === 201) {
                    resolve(res.data.data);
                  } else {
                    vm.$message({
                      message: this.$t('uploadFail'),
                      type: 'error'
                    });
                  }
                },
                error => {
                  reject(error);
                }
            );
          });
      }else {
        return false;
      }
    },
    async uploadImages(uploadUrlList) {
      let vm = this;
      if(uploadUrlList.length > 0){
        for(let i = 0; i < uploadUrlList.length; i++){
          let rawUrl = await vm.uploadCloudflare(uploadUrlList[i], vm.selectedImages[i]);
          // console.log(rawUrl.result.variants);
          let imgUrl = rawUrl.result.variants.filter(url => url.indexOf('/web') > -1)[0];
          let imgUrlToStore = imgUrl.replace('/web', '');
          vm.dataObj.urlList.unshift(imgUrlToStore);
        }
      }
      return true;
    },
    uploadCloudflare(uploadUrl, selectedImage){
      let formData = new FormData();
      formData.append('file', selectedImage, moment().format());
      return new Promise((resolve, reject) => {
        fetch(uploadUrl, {
          method: 'post',
          body: formData
        }).then(res => {
          resolve(res.json());
        }).catch(err => {
          reject(err);
        });
      })
    },

    queryLocationOption(countryId) {
      this.queryLocation(countryId);
    },
    queryProjectOption(q) {
      let vm = this;
      vm.queryProject(q, vm.dataObj.phoneNumberPrefix);

    },
    queryDistrictOption(locationId) {
      this.queryDistrict(locationId);
    },
    queryStateOption(countryId) {
      this.queryState(countryId);
    },
    async handleSubmit() {
      let vm = this;
      if (vm.$refs.formData.validate()) {
        
        vm.isLoading = true;
        if (vm.dataObj._id) {
          let uploadUrlList = await vm.getUploadUrl();
          if(uploadUrlList){
            await vm.uploadImages(uploadUrlList);
          }
          if(vm.imgToDelete.length > 0){
            let urlDelete = provider.baseURL + `/property/removeCloudFlareImages`;
            axios.post(urlDelete, {urlList: vm.imgToDelete}, {headers: {token: provider.token}}).then(
              res => {
                if(res.data.data == true){
                  console.log('deleted images')
                }
              },
              error => {
                console.log(error)
              }
            )
          }
          let url =
              provider.baseURL + provider.updateProperty;
          return new Promise((resolve, reject) => {
            axios.post(url, vm.dataObj, {headers: {token: provider.token}}).then(
                res => {
                  if (res.data.code === 201) {
                    resolve(res.data.data.content);
                    vm.isLoading = false;
                    vm.$message({
                      message: this.$t('updateSuccess'),
                      type: 'success'
                    });
                    vm.$router.push({name: "my-profile", params: {userId: localStorage.id}});

                  } else {
                    vm.isLoading = false;
                    vm.$message({
                      message: this.$t('updateFail'),
                      type: 'error'
                    });
                  }
                },
                error => {
                  reject(error);
                  vm.isLoading = false;
                }
            );
          });
        } else {
          vm.dataObj.createdUser = localStorage.id;
          let url =
              provider.baseURL + provider.addProperty;
          let uploadUrlList = await vm.getUploadUrl();
          if(uploadUrlList){
            await vm.uploadImages(uploadUrlList);
          }
          // console.log(vm.dataObj)
          return new Promise((resolve, reject) => {
            axios.post(url, vm.dataObj, {headers: {token: provider.token}}).then(
                res => {
                  // console.log(res)
                  if (res.data.code === 201) {
                    resolve(res.data.data.content);
                    vm.isLoading = false;
                    vm.$message({
                      message: this.$t('addSuccess'),
                      type: 'success'
                    });
                    vm.$router.push({name: "my-profile", params: {userId: localStorage.id}});

                  } else {
                    vm.isLoading = false;
                    vm.$message({
                      message: this.$t('addFail'),
                      type: 'error'
                    });
                  }
                },
                error => {
                  reject(error);
                  vm.isLoading = false;
                }
            );
          });
        }
      }

    },
    handleUpdate(doc) {
      let vm = this;
      vm.dataObj = Object.assign({}, doc);
      if(doc.urlList.length > 0){
        doc.urlList.forEach(url => {
          if(url.indexOf('firebasestorage') > -1){
            vm.newUrlList.push(url)
          }else{
            vm.newUrlList.push(url + "/web")
          }
        })
      }
      delete vm.dataObj.userDoc;
      delete vm.dataObj.unitName;
      delete vm.dataObj.updatedAt;
      delete vm.dataObj.createdAt;
      delete vm.dataObj.createdUser;
      delete vm.dataObj.view;
      vm.optionsA.position = vm.dataObj && vm.dataObj.position || {lat: 12.5225312, lng: 104.920603};
      vm.mapOptions.center = vm.dataObj && vm.dataObj.position || {lat: 12.5225312, lng: 104.920603};
      vm.titleClick = "updateProperty";
      vm.dialog = true;

      setTimeout(function () {
        vm.newUrl1 = doc.url1;
        vm.newUrl2 = doc.url2;
        vm.newUrl3 = doc.url3;
        vm.newUrl4 = doc.url4;
        vm.newUrl5 = doc.url5;
        vm.newUrl6 = doc.url6;
        vm.newUrl7 = doc.url7;
        vm.newUrl8 = doc.url8;

      }, 300);

    },
    removeImage(i){
      let vm = this;
      // to remove new selected images
      if(vm.dataObj.urlList.length > 0){
        vm.selectedImages.splice(i - vm.dataObj.urlList.length, 1);
      }else{
        vm.selectedImages.splice(i, 1);
      }
      // to remove new images and old images
      if(i < vm.dataObj.urlList.length){
        vm.newUrlList.splice(i, 1);
        if(vm.dataObj.urlList.length > 0){
          vm.imgToDelete.push(vm.dataObj.urlList[i]);
          vm.dataObj.urlList.splice(i, 1);
        }
      }else{
        vm.newUrlList.splice(i, 1);
      }
      console.log(vm.imgToDelete)
    }
  },
  watch: {
    dialog(val) {
      let vm = this;
      if (val === false) {
        this.$refs.formData.reset();
        vm.dataObj._id = "";
        vm.newUrl1 = "";
        vm.newUrl2 = "";
        vm.newUrl3 = "";
        vm.newUrl4 = "";
        vm.newUrl5 = "";
        vm.newUrl6 = "";
        vm.newUrl7 = "";
        vm.newUrl8 = "";
        vm.dataObj.position = {lat: 12.5225312, lng: 104.920603};

      }
    },

    "dataObj.width"(val) {
      let vm = this;
      if (vm.dataObj._id === ""){
        vm.dataObj.size = vm.$_numeral(vm.$_numeral(val * vm.dataObj.depth || 0).format("0,00.00")).value();
      }
    },
    "dataObj.currencyCode"(val) {
      let vm = this;
      let currencyDoc = vm.currencyList.find(o => o.code === val);
      if (currencyDoc) {
        vm.dataObj.currencySymbol = currencyDoc.symbol_native;
      }
    },
    "dataObj.depth"(val) {
      let vm = this;
      if (vm.dataObj._id === "") {
        vm.dataObj.size = vm.$_numeral(vm.$_numeral(val * vm.dataObj.width || 0).format("0,00.00")).value();
      }
    },
    "dataObj.phoneNumberPrefix"(val) {
      let vm = this;
      vm.locationOptionList = [];

      this.queryLocationOption(val);

    },
    "dataObj.locationId"(val) {
      this.queryDistrictOption(val);
    },

    "dataObj.price"(val) {
      let vm = this;
      if (vm.dataObj.size && val !== 0 && vm.dataObj.size !== 0) {
        vm.dataObj.pricePerSquare = vm.$_numeral(vm.$_numeral(val / vm.dataObj.size).format("0,00.00")).value();
      } else {
        vm.dataObj.pricePerSquare = 0;
      }
    },
    "dataObj.size"(val) {
      let vm = this;
      if (vm.dataObj.price && val !== 0) {
        vm.dataObj.pricePerSquare = vm.$_numeral(vm.$_numeral(vm.dataObj.price / val).format("0,00.00")).value();
      } else {
        vm.dataObj.pricePerSquare = 0;
      }
    },
    searchProject(val) {
      this.queryProjectOption(val);
    }

  },
  computed: {
    currentPageComputed: {
      get() {
        return this.currentPage;
      },
      set(value) {
        this.currentPage = numeral(value).value()
      }
    }
  },
  created() {
    let vm = this;

    let propertyId = vm.$route.params.propertyId;
    if (propertyId) {
      let url =
          provider.baseURL + `/property/byId?propertyId=${propertyId}`;
      return new Promise((resolve, reject) => {
        axios.get(url, {headers: {token: provider.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.handleUpdate(res.data.data);
              }
            },
            error => {
              reject(error);
            }
        );
      });

    } else {
      if (vm.$store.state.country && vm.$store.state.country !== "") {
        let cDoc = countyMobileList.find((o) => {
          return o.value === this.$store.state.country || o.number === this.$store.state.country;
        });
        vm.dataObj.phoneNumberPrefix = cDoc.number;
        vm.mapOptions.center = cDoc.position;
        vm.optionsA.position = cDoc.position;
        vm.circle.center = cDoc.position;
        vm.titleClick = "addProperty";
      }
    }
    vm.isFreeMember = vm.checkRole("Member");


  }
}
</script>
<style scoped>
#map {
  max-height: 800px;
}

.map-control {
  /* background-color: #fff; */
  /* border: 1px solid #ccc; */
  /* box-shadow: 0 2px 2px rgba(33, 33, 33, 0.4); */
  font-family: "Roboto", "sans-serif";
  margin: 10px;
  padding: 10px;
  /* Hide the control initially, to prevent it from appearing
           before the map loads. */
  display: none;
  font-size: 18px;
  line-height: 30px;
  vertical-align: center;
}

/* Display the control once it is inside the map. */
#map .map-control {
  display: block;
}

.selector-control {
  font-size: 18px;
  line-height: 30px;
  vertical-align: center;
}
</style>
